import React from "react";
import PhotoContainerComponent from "./PhotoContainerComponent";
import { PAGE_THREE_PHOTOS } from "../Constants";
import "./StepOneComponent.css";

const StepFourComponent = () => {
  return (
    <PhotoContainerComponent
      description="А как все продолжалось?"
      photos={PAGE_THREE_PHOTOS}
    />
  );
};

export default StepFourComponent;
