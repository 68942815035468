import React from "react";
import PhotoContainerComponent from "./PhotoContainerComponent";
import { PAGE_FOUR_PHOTOS } from "../Constants";
import "./StepOneComponent.css";

const StepFiveComponent = () => {
  return <PhotoContainerComponent description="" photos={PAGE_FOUR_PHOTOS} />;
};

export default StepFiveComponent;
