import React from "react";
import { MEME_ONE_PAGE } from "../Constants";
import "./StepOneComponent.css";

const StepOneComponent = () => {
  return (
    <div className="container" style={{ minHeight: "100vh" }}>
      <div className="row justify-content-center">
        <div className="col-xs-6 text-center p-3">
          <img
            src={MEME_ONE_PAGE.url}
            alt={MEME_ONE_PAGE.alt}
            className="img-fluid mb-3"
            style={{ maxHeight: "650px" }}
          />
          <p className="meme-text">
            *Не придется не только работать, а еще и не получать скучных и
            однотипных подарков)
          </p>
        </div>
      </div>
    </div>
  );
};

export default StepOneComponent;
