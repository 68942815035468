import React from "react";
import PhotoContainerComponent from "./PhotoContainerComponent";
import { PAGE_FIVE_PHOTOS } from "../Constants";
import "./StepOneComponent.css";

const StepSixComponent = () => {
  return <PhotoContainerComponent description="" photos={PAGE_FIVE_PHOTOS} />;
};

export default StepSixComponent;
