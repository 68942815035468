import React from "react";
import { Button } from "./Button";
import { NUMBER_OF_STEPS } from "../Constants";

const Layout = ({
  children,
  currentStep,
  handleCurrentStepNext,
  handleCurrentStepPrevious,
}) => {
  return (
    <div className="flex-column w-100" style={{ minHeight: "100vh" }}>
      <div className="flex-grow-1">{children}</div>

      {currentStep !== 0 && (
        <div
          className="position-fixed bottom-0"
          style={{ width: "100%", left: 0, right: 0 }}
        >
          <div className="d-flex justify-content-between px-4 py-3">
            {currentStep > 1 && (
              <Button
                btnStyle="position-absolute bottom-0 start-0"
                action={handleCurrentStepPrevious}
              >
                &#8592; Назад
              </Button>
            )}
            {currentStep < NUMBER_OF_STEPS && (
              <Button
                btnStyle="position-absolute bottom-0 end-0"
                action={handleCurrentStepNext}
              >
                Вперед &#8594;
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;
