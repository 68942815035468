import React from "react";
import PhotoContainerComponent from "./PhotoContainerComponent";
import { PAGE_EIGHT_PHOTOS } from "../Constants";
import "./StepOneComponent.css";

const StepNineComponent = () => {
  return <PhotoContainerComponent description="" photos={PAGE_EIGHT_PHOTOS} />;
};

export default StepNineComponent;
