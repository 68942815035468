import React from "react";
import ConfettiComponent from "./Confetti";
import "./confetti.scss";
import CenteredTextComponent from "./CenteredTextComponent";
import { GIFT } from "../Constants";

const StepFourteenComponent = () => {
  const containerStyle = {
    top: "0 !important",
  };

  return (
    <div>
      <div className="js-container container-custom" style={containerStyle}>
        <ConfettiComponent />
        <CenteredTextComponent center={true} title="Подарок" text={GIFT} />
      </div>
    </div>
  );
};

export default StepFourteenComponent;
