import React from "react";

const CenteredTextComponent = ({ title, text, center }) => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col mt-5">
          <h2
            style={{
              fontSize: "64px",
              fontWeight: "bold",
              fontFamily: "Russo One",
              textAlign: "center",
            }}
          >
            {title}
          </h2>
          <p className="p-3"
            style={{
              textAlign: center ? "center" : "justify",
              fontSize: "18px",
              fontWeight: "lighter",
              fontFamily: "Russo One",
              whiteSpace: "pre-line",
            }}
          >
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CenteredTextComponent;
