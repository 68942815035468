import React from "react";
import PhotoContainerComponent from "./PhotoContainerComponent";
import { PAGE_SIX_PHOTOS } from "../Constants";
import "./StepOneComponent.css";

const StepSevenComponent = () => {
  return (
    <PhotoContainerComponent
      description="А потом появился я и понеслась)"
      photos={PAGE_SIX_PHOTOS}
    />
  );
};

export default StepSevenComponent;
