import React from "react";
import PhotoContainerComponent from "./PhotoContainerComponent";
import { PAGE_TWO_PHOTOS } from "../Constants";
import "./StepOneComponent.css";

const StepThreeComponent = () => {
  return <PhotoContainerComponent description="" photos={PAGE_TWO_PHOTOS} />;
};

export default StepThreeComponent;
