import React, { useState } from "react";
import InputWithDescriptionAndButton from "./components/PasswordComponent";
import {
  PASSWORD,
  HANNA_CONGRATULATION,
  ANTON_CONGRATULATION,
} from "./Constants";
import StepOneComponent from "./components/StepOneComponent";
import StepTwoComponent from "./components/StepTwoComponent";
import StepThreeComponent from "./components/StepThreeComponent";
import StepFourComponent from "./components/StepFourComponent";
import StepFiveComponent from "./components/StepFiveComponent";
import StepSixComponent from "./components/StepSixComponent";
import StepSevenComponent from "./components/StepSevenComponent";
import StepEightComponent from "./components/StepEightComponent";
import StepNineComponent from "./components/StepNineComponent";
import StepTenComponent from "./components/StepTenComponent";
import StepElevenComponent from "./components/StepElevenComponent";
import StepTwelveComponent from "./components/StepTwelveComponent";
import StepThirteenComponent from "./components/StepThirteenComponent";
import StepFourteenComponent from "./components/StepFourteenComponent";
import CenteredTextComponent from "./components/CenteredTextComponent";
import Layout from "./components/Layout";
import BackgroundMusic from "./components/BackgroundMusic";

function App() {
  const [password, setPassword] = useState("");
  const [currentStep, setCurrentStep] = useState(0);

  const handleInputChange = (newValue) => {
    setPassword(newValue);
  };

  const handleSubmit = () => {
    if (password === PASSWORD) {
      setCurrentStep(currentStep + 1);
    }
    setPassword("");
  };

  const handleCurrentStepNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleCurrentStepPrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="App">
      {currentStep !== 0 && (
        <BackgroundMusic
          songUrl={process.env.PUBLIC_URL + "/Until I found you.mp3"}
        />
      )}
      <Layout
        currentStep={currentStep}
        handleCurrentStepNext={handleCurrentStepNext}
        handleCurrentStepPrevious={handleCurrentStepPrevious}
      >
        {currentStep === 0 ? (
          <div style={{ backgroundColor: "#778899" }}>
            <InputWithDescriptionAndButton
              description="ssid: Down"
              value={password}
              onChange={handleInputChange}
              onSubmit={handleSubmit}
            />
          </div>
        ) : (
          <div>
            {currentStep === 1 && (
              <div style={{ backgroundColor: "#808080" }}>
                {" "}
                <StepOneComponent />{" "}
              </div>
            )}
            {currentStep === 2 && <StepTwoComponent />}
            {currentStep === 3 && <StepThreeComponent />}
            {currentStep === 4 && <StepFourComponent />}
            {currentStep === 5 && <StepFiveComponent />}
            {currentStep === 6 && <StepSixComponent />}
            {currentStep === 7 && <StepSevenComponent />}
            {currentStep === 8 && <StepEightComponent />}
            {currentStep === 9 && <StepNineComponent />}
            {currentStep === 10 && <StepTenComponent />}
            {currentStep === 11 && <StepElevenComponent />}
            {currentStep === 12 && <StepTwelveComponent />}
            {currentStep === 13 && <StepThirteenComponent />}
            {currentStep === 14 && (
              <div className="text-container" style={{ backgroundColor: "#6bb3ea" }}>
                <CenteredTextComponent
                  title="Поздравление Ани"
                  text={HANNA_CONGRATULATION}
                />
              </div>
            )}
            {currentStep === 15 && (
              <div className="text-container" style={{ backgroundColor: "#fcb8ea" }}>
                <CenteredTextComponent
                  title="Поздравление Антона"
                  text={ANTON_CONGRATULATION}
                />
              </div>
            )}
            {currentStep === 16 && <StepFourteenComponent />}
          </div>
        )}
      </Layout>
    </div>
  );
}

export default App;
