import React from "react";

const InputWithDescriptionAndButton = ({
  description,
  value,
  onChange,
  onSubmit,
}) => {
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <div className="container" style={{ minHeight: "100vh" }}>
      <div
        className="row justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="col-md-6">
          <div className="text-center mb-3">
            <label>{description}</label>
          </div>
          <div className="input-group">
            <input
              type="password"
              className="form-control"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <div className="input-group-append">
              <button onClick={onSubmit}>Подтвердить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputWithDescriptionAndButton;
