import React from "react";
import PhotoContainerComponent from "./PhotoContainerComponent";
import { PAGE_TEN_PHOTOS } from "../Constants";
import "./StepOneComponent.css";

const StepElevenComponent = () => {
  return (
    <PhotoContainerComponent
      description="А как все продолжало продолжаться?"
      photos={PAGE_TEN_PHOTOS}
    />
  );
};

export default StepElevenComponent;
