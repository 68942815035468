import React from "react";
import "./PhotoObjectComponent.scss";

const PhotoObjectComponent = ({ url, alt, delay }) => {
  const getRandomAnimation = () => {
    return Math.random() < 0.5 ? "scaleUp" : "scaleDown";
  };

  const animationName = getRandomAnimation();

  const style = {
    animationDelay: `${delay}s`,
  };

  return (
    <div
      className={`col-md-4 d-flex justify-content-center align-items-center photo-object ${animationName}`}
      style={style}
    >
      <img src={url} alt={alt} className="img-fluid photo" />
    </div>
  );
};

export default PhotoObjectComponent;
