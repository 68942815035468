import React from "react";
import PhotoContainerComponent from "./PhotoContainerComponent";
import { PAGE_TWELVE_PHOTOS } from "../Constants";
import "./StepOneComponent.css";

const StepThirteenComponent = () => {
  return (
    <PhotoContainerComponent
      description="А как все было ну совсем недавно?"
      photos={PAGE_TWELVE_PHOTOS}
    />
  );
};

export default StepThirteenComponent;
