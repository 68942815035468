import { useState, useEffect } from "react";

const BackgroundMusic = ({ songUrl }) => {
  const [audio] = useState(new Audio(songUrl));

  useEffect(() => {
    const handleSongEnd = () => {
      audio.currentTime = 0; // Reset the song to the beginning
      audio.play(); // Play the song again
    };

    audio.addEventListener("ended", handleSongEnd);

    return () => {
      audio.removeEventListener("ended", handleSongEnd);
    };
  }, [audio, songUrl]);

  useEffect(() => {
    const playAudio = () => {
      if (audio.readyState >= audio.HAVE_ENOUGH_DATA) {
        audio.play();
      }
    };

    if (!audio.paused) {
      audio.pause();
      audio.currentTime = 0;
    }

    audio.src = songUrl;
    audio.addEventListener("canplay", playAudio);

    return () => {
      audio.removeEventListener("canplay", playAudio);
    };
  }, [audio, songUrl]);

  return null;
};

export default BackgroundMusic;
