import React from "react";
import PhotoContainerComponent from "./PhotoContainerComponent";
import { PAGE_SEVEN_PHOTOS } from "../Constants";
import "./StepOneComponent.css";

const StepEightComponent = () => {
  return <PhotoContainerComponent description="" photos={PAGE_SEVEN_PHOTOS} />;
};

export default StepEightComponent;
