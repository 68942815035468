import React, { useState, useEffect } from "react";
import PhotoObjectComponent from "./PhotoObjectComponent";
import "./PhotoContainerComponent.css";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const PhotoContainerComponent = ({ photos, description }) => {
  const [shuffledPhotos, setShuffledPhotos] = useState([]);
  const [visiblePhotos, setVisiblePhotos] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState("white");
  const [containerHeight, setContainerHeight] = useState("100vh");

  useEffect(() => {
    const shuffled = shuffleArray(photos);
    setShuffledPhotos(shuffled);
  }, [photos]);

  useEffect(() => {
    const addPhotoWithDelay = (index) => {
      if (index < shuffledPhotos.length) {
        const timer = setTimeout(() => {
          setVisiblePhotos((prevVisiblePhotos) => [
            ...prevVisiblePhotos,
            shuffledPhotos[index],
          ]);
          addPhotoWithDelay(index + 1);
        }, 2000);

        return () => clearTimeout(timer);
      }
    };

    addPhotoWithDelay(0);
  }, [shuffledPhotos]);

  useEffect(() => {
    const calculateBackgroundColor = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const images = shuffledPhotos.map((photo) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = photo.url;
        return img;
      });

      let loadedImages = 0;

      images.forEach((img) => {
        img.onload = () => {
          loadedImages++;

          if (loadedImages === images.length) {
            canvas.width = images[0].width;
            canvas.height = images[0].height;

            images.forEach((img) => {
              context.drawImage(img, 0, 0);
            });

            const imageData = context.getImageData(
              0,
              0,
              canvas.width,
              canvas.height,
            ).data;

            let totalR = 0;
            let totalG = 0;
            let totalB = 0;

            for (let i = 0; i < imageData.length; i += 4) {
              totalR += imageData[i];
              totalG += imageData[i + 1];
              totalB += imageData[i + 2];
            }

            const numPixels = imageData.length / 4;
            const avgR = totalR / numPixels;
            const avgG = totalG / numPixels;
            const avgB = totalB / numPixels;

            const avgColor = `rgb(${avgR}, ${avgG}, ${avgB})`;
            setBackgroundColor(avgColor);
          }
        };
      });
    };

    calculateBackgroundColor();
  }, [shuffledPhotos]);

  useEffect(() => {
    const resizeHeight = () => {
      const isMobileView = window.matchMedia("(max-width: 768px)").matches;
      if (isMobileView) {
        const vh = document.body.scrollHeight + window.innerWidth / 2;
        setContainerHeight(`${vh}px`);
      }
    };

    window.addEventListener("resize", resizeHeight);
    resizeHeight();

    return () => {
      window.removeEventListener("resize", resizeHeight);
    };
  }, [visiblePhotos]);

  return (
    <div
      className="photo-container"
      style={{ backgroundColor, height: containerHeight }}
    >
      <p className="text-center">{description}</p>
      <div className="row">
        {visiblePhotos.map((photo, idx) => (
          <PhotoObjectComponent key={idx} url={photo.url} alt={photo.alt} />
        ))}
      </div>
    </div>
  );
};

export default PhotoContainerComponent;
