import React from "react";
import PhotoContainerComponent from "./PhotoContainerComponent";
import { PAGE_ELEVEN_PHOTOS } from "../Constants";
import "./StepOneComponent.css";

const StepTwelveComponent = () => {
  return <PhotoContainerComponent description="" photos={PAGE_ELEVEN_PHOTOS} />;
};

export default StepTwelveComponent;
